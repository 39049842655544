@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,900&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body {
  height: 100%;
  background-color: #d0d6df;
}
#root {
  min-width: 100%;
  width: fit-content;
  height: 100vh;
}

:root {
  --dark_bg: #1e1e33;
  --dark_outline: #3d3d54;
  --white: #ffffff;
  --accent: #17c1c1;
  --accenthov: #26dac9;
  --error: #d3234f;
  --lightgray: #f2f4f6;
  --gray: #d0d6df;
  --body_color: #6f6f82;
}

/*  COMPONENT */
.component_logoimg {
  object-fit: contain;
}
.container_gridlayout {
  box-shadow: 1px 2px 8px 1px #0000005e;
  background-color: var(--body_color);
  padding: 20px;
  width: fit-content;
  margin: auto;
  overflow: hidden;
}

.react-grid-layout.landscape {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1920px;
  height: 1080px !important;
  min-height: 1080px;
  max-height: 1080px;
}


.react-grid-layout.portrait {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1080px;
  height: 1920px !important;
  min-height: 1920px;
  max-height: 1920px;}
/* Container base*/
.container_component {
  width: 100%;
  height: 100%;
  transition: all 250ms ease;
  display: flex;
}
/* TITLEBAR*/

.icon_container {
  height: 100%;
}
.text_title h1 {
  line-height: 0;
  width: max-content;
  font-size: inherit;
  align-self: center;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
}
.text_title {
  overflow: hidden;
}
/*  PROMO CAROUSEL */
.promo {
  border: 1px solid #d6d6d6;
  padding: 5px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.promo .carousel {
  height: 100%;
}

.promo .carousel-inner {
  height: 100%;
}

.promo .carousel-item {
  height: 100%;
}
.promo .promo_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slide-demo {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  place-items: center;
  justify-content: center;
}

.slide-demo h2 {
  text-align: center;
  color: #2e2e2e;
  font-size: 3em;
}
/* PROGRESS */
body .progress_container {
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
}
body .progress {
  display: flex;
  height: 0.7rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #ababab;
}

body .progress .progress-bar{
  background-color: #ffffff;
}


body .progress.progress-rectangle {
  margin: auto 220px auto 40px;
  width: 100%;
}
.circle-fluidity {
  background-color: #d6d6d6;
  width: 150px;
  height: 150px;
  max-width: 140px;
  padding: 0;
  display: block;
  position: absolute;
  right: 40px;
  border-radius: 100%;
  bottom: -50px;
  box-shadow: 1px 2px 8px 1px #0000005e;
}

/* INFOFLUIDITY */
.listcontainer-infoFluidity {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.number-infoFluidity {
  font-weight: bold;
  font-family: "Montserrat";
  color: #282c2d;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textcontainer-infoFluidity {
  border-bottom: 3px solid #d6d6d6;
  height: 100%;
  align-self: center;
}
.item-infoFluidity:nth-last-child(1)  .textcontainer-infoFluidity{
  border-bottom: none;
}
.item-infoFluidity{
  margin-bottom: 15px;
}
.noresize .react-resizable-handle {
  display: none;
}

.resizeSVG svg {
  width: 100%;
  height: 100%;
}

.resizeSVGImageFoodGroup svg {
  min-height: 100%;
  width: 100%;
  max-height: 400px;
  flex-shrink: 0;
  display: flex;
  height: 0;
  padding: 30px;
  }
.svgTitle {
width: 100%;
height: 100%;
}

.imageCarousel {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.multiFoodGroupList div:last-child {
  border: none;
}
.multiFoodGroupList div  {
  border-bottom: 1px solid #d6d6d6;
}


/* NEW */


.iconWeather svg{
  height: 100%;
  width: auto;
}

.itemCardFg:nth-last-child(1){
 margin-bottom:20px;
}
.itemCardFg{
 margin-bottom:0;
}
.containerCardFg{
    width: calc(100% - 110px);
     /* height: 100%; */
}

.newshadow {
  box-shadow: 0px 4px 7px 0px #1e1e3382;
}



  .imageCarousel {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.video-section{
height: 100%;
    background-color: #282c2d;}

.video-section embed video{  

    width: 100%;
    height: 100%;}

    .textpromo-title{
      display: block;
      width: 100%;
      text-align: center;
    }

    .slide-textpromo{
  display: flex;
    height: 100%;
      width: 100%;
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }